<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body" id="printMe">
        <div class="select-two main-search">
          <div class="st-block">
            <!-- <div class="st-label">组名:</div> -->
            <div class="st-select">
              {{ workshopName }}
            </div>
            <div class="st-select">
              {{ groupName }}
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="btn-block" @click.stop="() => getdevcieByWorkshop()">
            <i class="iconfont iconicon-zengjia"></i>
            新增
          </div>
        </div>
        <div class="table-area" :class="isMin == true ? 'minTableArea' : ''">
          <Table
            no-data-text=" "
            height="448"
            class="devTable"
            border
            ref="selection"
            :loading="loading"
            :columns="theadDate"
            :data="tableDate"
            :class="{ minTable: isMin }"
          ></Table>
        </div>
        <div class="main-page" style="margin-top:21px">
          <Page
            :total="total"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize"
            :current="pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
      <Modal
        v-model="modalFlagAppend"
        :title="workshopName + '  ' + groupName"
        width="634"
        :mask-closable="false"
        class="modal"
      >
        <Input
          suffix="ios-search"
          v-model="text"
          placeholder="请输入机台编号"
          @keyup.enter.native="changeInput"
          style="width: 280px;margin-bottom:10px;"
        />
        <div class="table-area" :class="isMin == true ? 'minTableArea' : ''">
          <Table
            no-data-text=" "
            max-height="560"
            height="320"
            class="devTable"
            border
            ref="selection"
            :loading="loading"
            :columns="theadDate1"
            :data="tableDate1"
            @on-selection-change="getSections"
            :class="{ minTable: isMin }"
          ></Table>
        </div>
        <div class="main-page" style="margin-top:21px">
          <Page
            :total="total1"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize1"
            :current="pageNum1"
            @on-page-size-change="pagesizechange1"
            @on-change="pagenumberchange1"
          />
        </div>
        <div slot="footer">
          <Button @click="handleReset" class="white-btn">取 消</Button>
          <Button type="primary" @click="addDeviceToGroup()">新 增</Button>
        </div>
      </Modal>
      <Modal v-model="modalDelete" title="删除" width="440">
        <div style="margin-bottom:17px">
          <i class="iconfont iconicon-gantanhao modalGantanhao"></i
          >是否确定删除？
        </div>
        <div class="modalDelMessage">
          删除操作不可撤销，删除的内容将不可恢复。
        </div>
        <div slot="footer">
          <Button @click="modalDelete = false" class="white-btn">取消</Button>
          <Button type="primary" @click="realDelete">确定</Button>
        </div>
      </Modal>
    </Content>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  data() {
    return {
      deviceId: "",
      isMin: false,
      creenWidth: "", // 屏幕宽度
      groupName: "",
      workshopName: "",
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pages: 0,
      pageSize1: 10,
      pageNum1: 1,
      total1: 0,
      pages1: 0,
      text: "",
      selectDev: {},
      modalFlagAppend: false,
      modalDelete: false,
      departForm: {
        name: "",
        mark: ""
      },
      departName: "",
      theadDate: [
        {
          title: "设备编号",
          key: "deviceNum",
          align: "center"
        },
        {
          title: "设备类型",
          key: "deviceType",
          align: "center"
        },
        {
          title: "操作",
          key: "action",
          width: "",
          align: "center",
          render: (h, params) => {
            const arr = [];
            arr.push(
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  style: {
                    marginRight: "5px",
                    boxShadow: "none",
                    color: "rgba(87,163,243,1)"
                  },
                  on: {
                    click: () => {
                      this.delDeviceByGroup(params.row.deviceNum);
                      // this.modalDelete = true;
                    }
                  }
                },
                "删除"
              )
            );
            return h("div", arr);
          }
        }
      ],
      tableDate: [],
      theadDate1: [
        {
          type: "selection",
          align: "left",
          width: "60"
        },
        {
          title: "机台编号",
          key: "deviceNum",
          align: "left"
        },
        {
          title: "设备类型",
          key: "deviceType",
          align: "left"
        }
      ],
      tableDate1: [],
      loading: false
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
        // console.log(this.screenWidth);
        if (this.screenWidth > 1600) {
          this.isMin = true;
        } else {
          this.isMin = false;
        }
      })();
    };
  },
  created() {
    this.getMachineByGroup();
  },
  methods: {
    // 修改每页条数
    pagesizechange(pagesize) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.getMachineByGroup();
    },
    // 修改页码
    pagenumberchange(pagenum) {
      this.pageNum = pagenum;
      this.getMachineByGroup();
    },

    // 修改每页条数
    pagesizechange1(pagesize) {
      this.pageSize1 = pagesize;
      this.pageNum1 = 1;
      this.getdevcieByWorkshop();
    },
    // 修改页码
    pagenumberchange1(pagenum) {
      this.pageNum1 = pagenum;
      this.getdevcieByWorkshop();
    },
    // 点击取消 关闭新增弹框
    handleReset() {
      this.modalFlagAppend = false;
    },
    getSections(e) {
      var arr = [];
      if (e.length > 0) {
        for (var i = 0; i < e.length; i++) {
          arr.push(e[i].deviceNum);
        }
      }
      this.selectDev = arr;
    },
    getMachineByGroup() {
      this.groupName = "组名：" + this.$route.query.row.groupName;
      this.workshopName =
        "车间名称：" + this.$route.query.row.workshopName + "\xa0\xa0\xa0";
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/deviceGroupView",
        method: "get",
        params: {
          groupId: this.$route.query.row.id,
          pageNum: that.pageNum,
          pageSize: that.pageSize
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate = res.data.body.DeviceGroup.list;
            that.pageNum = res.data.body.DeviceGroup.pageNum;
            that.pageSize = res.data.body.DeviceGroup.pageSize;
            that.total = res.data.body.DeviceGroup.total;
            that.pages = res.data.body.DeviceGroup.pages;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdevcieByWorkshop() {
      this.modalFlagAppend = true;
      const that = this;
      this.axios({
        url:
          "/dtsum/zongtong/device/DeviceController/deviceListInAddDeviceToGroup",
        method: "get",
        params: {
          workShopId: this.$route.query.row.workShopId,
          pageNum: that.pageNum1,
          pageSize: that.pageSize1
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate1 = res.data.body.DeviceList.list;
            that.pageNum1 = res.data.body.DeviceList.pageNum;
            that.pageSize1 = res.data.body.DeviceList.pageSize;
            that.total1 = res.data.body.DeviceList.total;
            that.pages1 = res.data.body.DeviceList.pages;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeInput() {
      const that = this;
      this.axios({
        url:
          "/dtsum/zongtong/device/DeviceController/deviceListInAddDeviceToGroup",
        method: "get",
        params: {
          workShopId: this.$route.query.row.workShopId,
          deviceNumber: that.text,
          pageNum: that.pageNum1,
          pageSize: that.pageSize1
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate1 = res.data.body.DeviceList.list;
            that.pageNum1 = res.data.body.DeviceList.pageNum;
            that.pageSize1 = res.data.body.DeviceList.pageSize;
            that.total1 = res.data.body.DeviceList.total;
            that.pages1 = res.data.body.DeviceList.pages;
          } else {
            this.$Message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addDeviceToGroup() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/addDeviceToGroup",
        method: "post",
        data: {
          groupId: this.$route.query.row.id,
          deviceNumbers: that.selectDev
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.getMachineByGroup();
            this.$Message.success("添加成功！");
            this.handleReset();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除设备组中的某台设备
    delDeviceByGroup(params) {
      this.modalDelete = true;
      this.deviceId = params;
    },
    realDelete() {
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/deleteDeviceFromGroup",
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: Qs.stringify({
          deviceNumber: this.deviceId
        })
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.getMachineByGroup();
            this.$Message.success("删除成功");
            this.modalDelete = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-two {
    // margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .st-block {
      height: 36px;
      line-height: 36px;
      display: flex;
      .st-label {
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        height: 36px;
      }
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  .st-select {
    width: 220px !important;
  }
  .table-area {
    .ivu-table-wrapper /deep/ .ivu-table {
      .ivu-table-body {
        // position: fixed !important;
        tbody .ivu-table-row {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
          }
        }
        .ivu-table-wrapper /deep/ .ivu-table {
          .ivu-table-body {
            // position: fixed !important;
            tbody .ivu-table-row {
              td .ivu-table-cell .ivu-select .ivu-select-selection {
                border: none;
                box-shadow: none;
              }
              .blueFont /deep/ {
                color: rgba(87, 163, 243, 1);
                .ivu-table-cell .ivu-btn {
                  span {
                    border-bottom: 1px solid rgba(87, 163, 243, 1);
                    height: 18px;
                  }
                }
              }
            }
            tbody .ivu-table-row:hover {
              td .ivu-table-cell .ivu-select .ivu-select-selection {
                border: none;
                box-shadow: none;
                background: rgba(235, 247, 255, 1);
              }
              td .ivu-table-cell .ivu-btn {
                border: none;
                box-shadow: none;
                background: rgba(235, 247, 255, 1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.modal .ivu-input-suffix {
  line-height: 36px;
}
.modalGantanhao {
  color: #ff1414;
  margin-right: 12px;
}
</style>
